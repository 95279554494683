body {
  margin: 0;
  /* font-family: 'Raleway', sans-serif;*/
  /*font-family: 'Inter', sans-serif;*/
  font-family: "Oswald", sans-serif;
  background: #fffafa;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  padding: 0;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --bg: #fffaff;
  --bg-accent: #1e8d34;
  --text-nav: #151616;
  --text-nav-dark: #151616;
  --text-nav-light: #ffffff;
  --nav-size: 100px;
  --text-color: var(--bg);
  --speed: 500ms;
  --nav-font-size: 6vw;
  --nav-font-outline: 0.15rem;
  --nav-font-mobile: 7em;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a,
h1,
h2,
h3,
h5 {
  color: var(--text-color);
  text-decoration: none;
  font-weight: unset;
}

.navbar {
  display: flex;
  flex-wrap: wrap;
  height: var(--nav-size);
  padding: 0 1rem;
  border-bottom: var(--border);
  z-index: 3;
}

.navbar-nav {
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  font-size: var(--nav-font-size);
  transition: top 0.6s;
}

@media screen and (max-width: 600px) {
  .navbar-nav {
    position: relative;
    font-size: var(--nav-font-mobile);
  }
}

.nav-item {
  top: 0;
  color: var(--text-nav);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  padding-left: 10px;
  padding-right: 10px;
  text-shadow: 1px 1px 3px var(--text-nav);
}

.nav-item a {
  color: inherit;
}

.nav-item a.active {
  color: transparent;
  text-shadow: none;
  -webkit-text-stroke-width: var(--nav-font-outline);
  -webkit-text-stroke-color: var(--text-nav);
}

.logo-bar {
  display: none;
  width: 150px;
  background-color: #fff;
  bottom: 10px;
  z-index: 0;
  margin-top: 20px;
  /* position: fixed;
  right: 0; */
  justify-content: flex-start;
  align-self: flex-end;
  height: 150px;

  cursor: pointer;
  box-shadow: rgba(3, 8, 20, 0.1) 0px 0.15rem 0.5rem,
    rgba(2, 8, 20, 0.1) 0px 0.075rem 0.175rem;
}

.logo-bar div {
  display: none;
  visibility: hidden;
}

.logo {
  width: 150px;
  /* right: 0; */
  bottom: 10px;
  z-index: 2;
  /* position: fixed; */
  /* filter: invert(100%) sepia(0%) saturate(7499%) hue-rotate(89deg) brightness(110%) contrast(100%); */
}

@media screen and (max-width: 600px) {
  .logo {
    width: 20vw;
    right: 0;
    bottom: 0;
    height: fit-content;
  }

  .logo-bar {
    display: flex;
    overflow-y: hidden;
    font-size: 50px;
    height: 20vw;
    width: 100%;
    position: fixed;
    right: 0;
    background-color: black;
    backdrop-filter: blur(10px);
  }

  .logo-bar div {
    display: block;
    visibility: visible;
  }

  .logo-bar .contact-logo {
    margin: 10px;
    display: flex;
    width: 75%;
    justify-content: center;
    align-items: center;
    left: 0;
  }

  .logo-bar .contact-logo .contact-button {
    border-radius: 50%;
    color: #575557;
    background-color: white;
    width: 60px;
    height: 60px;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.work-card {
  height: fit-content;
  background-color: #151616;
  width: 100%;
  padding-bottom: 100px;
  margin: 0;
  position: relative;
  /* overflow: hidden; */
}

/* .work-card::before,
.team-card::before {
  border-bottom: 10vh solid #151616;
  border-left: 100vw solid transparent;
  bottom: 100%;
  content: "";
  display: block;
  height: 0;
  left: 0;
  position: absolute;
  right: 0;
}
.team-card::before {
  border-bottom: 10vh solid var(--bg);
}

@media screen and (max-width: 600px) {
  .work-card::before {
    border-bottom: 5vh solid #151616;
  }
  .team-card::before {
    border-bottom: 5vh solid var(--bg);
  }
} */

.home-card {
  height: 100vh;
  background-image: url("images/CAPA2.jpg");
  display: flex;
  padding-bottom: 100px;
  background-size: cover;
}

.team-card {
  height: fit-content;
  background-color: var(--bg);
  width: 100%;
  padding-bottom: 180px;
  margin-top: auto;
  position: relative;
  /* overflow: hidden; */
  /* justify-content: center; */
}

.team-grid {
  /*
  padding: 40px;
  display: grid;
  list-style: none;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-auto-rows: 300px;
  margin: 0px  0  50px 0 ;
  place-items: center;*/
  padding: 10%;
  display: flex;
  margin: 0px 0 50px 0;
  /* place-items: center; */
  justify-content: center;
  flex-wrap: wrap;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  font-size: 3rem;
  color: var(--text-nav);
  height: 100%;
  width: 25%;
  min-width: 300px;
  transition: all 500ms;
  overflow: hidden;
  aspect-ratio: 1/1;
  box-shadow: 0 0 0.2em black;
  /*cursor: pointer;*/
  background-color: transparent;
}

.card:hover img {
  filter: blur(1px);
  transform: scale(1.1);
  transition: 600ms;
}

.card img {
  width: 100%;
  /*height: 100%;*/
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: 600ms;
  filter: grayscale(100%);
  /*max-width: 350px;*/
}

@media screen and (min-width: 600px) {
  .card div {
    opacity: 0;
    transition: 800ms;
  }
}

.card:hover div {
  opacity: 1;
  transition: 800ms;
}

.card div {
  align-self: flex-end;
  position: absolute;
  font-size: 20px;
  color: #fff;
  padding-right: 50px;
  padding-bottom: 20px;
}

.card div h2 {
  font-size: 34px;
  margin-bottom: 0px;
}

@media screen and (min-width: 600px) {
  .card-tall {
    grid-row: span 2 / auto;
  }

  .card-wide {
    grid-column: span 2 / auto;
  }
}

.gallery {
  padding: 40px;
  margin: 0 auto;
  max-width: 80vw;
  position: relative;
  will-change: opacity;
}

.thumbnails {
  /* display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between; */

  align-items: center;
}

.thumbnails h1 {
  width: 100%;
}

.gallery-drawer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
}

.gallery-drawer h3{
  display: none;
}

@media (max-width: 600px) {
  .gallery-drawer h3{
    display: block;
  }
}

.thumbnail {
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  will-change: transform, opacity;
  flex: 1 0 40%;
  overflow: hidden;
}

@media (max-width: 800px) {
  .thumbnail {
    min-width: 95%;
  }
}

.frame {
  overflow: hidden;
}

.thumbnail img {
  width: 100%;
  aspect-ratio: 16/9;
}

.frame .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-items: center;
  z-index: 10;
}

.frame .overlay .overlay-text {
  font-size: 1.5rem;
  text-align: center;
  z-index: 3;
  position: absolute;
}

.single {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-left: 50px;
  margin-right: 50px;
}

.single .image-row {
  display: flex;
  width: 60%;
  flex-direction: column;
  justify-content: center;
}

.single .image-row img {
  object-fit: cover;
  max-height: 60vh;
  width: auto;
  height: auto;
}

@media (max-width: 800px) {
  .single .image-row {
    width: 90%;
  }

  .single .image-row img {
    max-height: 100vh;
  }
}

.single .caroussel {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  background-color: black;
  overflow: hidden;
}

.single .image-row .caroussel img {
  width: 100%;
  position: relative;
}
.next,
.prev {
  position: absolute;
  background: rgba(17, 16, 16, 0.562);
  border-radius: 30px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 20px;
  z-index: 2;
  border: #fff;
  border-width: 2px;
  border-style: solid;
  color: white;
}

.next {
  right: 10px;
}

.prev {
  left: 10px;
}

.project-description {
  margin-left: 50px;
  margin-right: 50px;
  color: var(--text-color);
  font-size: 25px;
}
.project-description h5 {
  margin: 10px;
}

@media (max-width: 800px) {
  .single img {
    max-width: 100%;
    margin-right: 0px;
    height: 400px;
  }
  .single {
    margin: 10px;
  }
}

.back {
  font-size: 32px;
  width: 100%;
  text-align: end;
}

.back a {
  text-decoration: none;
  margin-right: 50px;
}
@media (max-width: 600px) {
  .back a {
    
    margin-right: 5;
  }
}

.project-list-container {
  display: flex;
  justify-content: center;
}

.project-list {
  color: rgb(167, 167, 167);
  max-width: 80%;
}

.link {
  color: rgb(255, 255, 255);
  font-weight: 500;
}

.project-list a li:hover {
  color: gray;
  cursor: pointer;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  width: 500px;
  justify-content: flex-start;
}

@media (max-width: 800px) {
  .flex-container {
    max-width: 100%;
  }
}

.mobile-nav-marker {
  opacity: 0;
  padding: 0;
  height: 180px;
  margin: 0px;
  font-size: var(--nav-font-mobile);
}

.mobile-nav-marker.dark {
  -webkit-text-stroke-color: #151616;
}

.mobile-nav-marker.light {
  -webkit-text-stroke-color: #fff;
}

@media screen and (max-width: 600px) {
  .mobile-nav-marker {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    opacity: 1;
    color: transparent;
    -webkit-text-stroke-width: var(--nav-font-outline);
  }
}

.about-card {
  min-height: 100vh;
  height: fit-content;
  background-color: #151616;
  width: 100%;
  padding-bottom: 50px;
  margin-top: auto;
}

.about-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  color: #fff;
  margin-top: 2rem;
  font-size: 25px;
  flex-wrap: wrap;
  margin-bottom: 100px;
}
/* 
.about-container p::first-letter {
  font-size: 80px;
  padding-right: 8px;
  float:left;
  line-height: 0.7;
  text-shadow: 4px 4px gray;
  
} */

.about-container h1 {
  font-style: italic;
  font-weight: 600;
}

.contact-frame {
  display: flex;
  justify-content: center;
}


@media screen and (max-width: 600px) {
  .contact-frame {
   display: none;
  
  }
}

.contact-section {
  background-color: var(--bg);
  max-width: 50%;
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  margin-top: 50px;
  box-shadow: 0 0 0.5em #fff;
  padding-left: 50px;
  padding-right: 50px;


}

.contact-section h1 {
  font-size: 10vh;
  color: #151616;
  margin: 10px;
  text-transform: uppercase;
  text-align: center;
}

.contact-section p {
  margin: 0;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.contact-section h2 {
  font-size: 22px;
  color: #151616;
  margin: 0;
  margin-left: 10px;
  margin-right: 10px;
}

.contact-container { 
  display: flex;
  justify-content: center;
}

.contact-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-color: #fff;
  background-color: #ffffff09;
  border-width: 0.1rem;
  border-radius: 0.5rem;
  border-style: solid;
  min-height: 20rem;
  min-width: 60%;
  max-width: 100%;
  margin: 2rem; 
  padding-top: 2rem;
  padding-bottom: 2rem;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.13);
  box-shadow: 0 4px 30px rgba(255, 255, 255, 0.11);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 4px 30px rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
}

.contact-form-row{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  flex-wrap: wrap;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.contact-form-column{
  color: #fff;
  flex: 50%;
  width: 100%;
}

.contact-form-column select{
  margin: 0.1;
  width: 90%;
}



.contact-form input,select,textarea,button{
  background-color: #151616;
  border-color: #fff;
  color: #fff;
  border-radius: 0.25rem;
  border-width: 0.05rem;
  margin: 0.5rem;
  width: 80%;
  font-size: 1.2rem; font-family: "Oswald", sans-serif;
  padding: 0.25rem;
}

.button-right-container {
  display: flex;
  width: 80%;
  justify-content: flex-end;
}

.contact-form-button{
  background-color: #151616;
  border-color: #fff;
  color: #fff;
  border-radius: 0.25rem;
  border-width: 0.05rem;
  margin: 0.5rem;
  width: 20%;
  cursor: pointer;
}



.svg {
  stroke-width: 11;
}

.loading {
  display: flex;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #151616;
  z-index: 5000;
  align-items: center;
  justify-content: center;
}

.text-loading {
  color: #151616;
  position: fixed;
  margin-top: 172px;
  margin-left: 80px;
}

.text-loading-2 {
  color: #151616;
  position: absolute;
  margin-top: 172px;
  margin-left: 80px;
}
.text-loading p,
.text-loading-2 p {
  margin: 0;
  color: white;
}

.watermark {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: start;
  align-items: end;
}

.watermark img {
  padding-left: 3rem;
  padding-bottom: 3rem;
}

@media screen and (max-width: 600px) {
  .watermark {
    display: none;
  }
}

.language-switch {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 0.15em;
  justify-content: center;
}

.language-switch h2 {
  margin: 0;
  color: var(--text-nav);
  cursor: pointer;
}

.language-switch hr {
  width: 1rem;
}

.language-switch h2.active {
  font-style: italic;
  font-weight: 800;
}

@media screen and (max-width: 600px) {
  .language-switch {
    width: 100%;
  }
}

.about-text {
  max-width: 50%;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
}

.about-text h1 {
  width: 100%;
}

.map-container {
  width: 100%;
  /* aspect-ratio: 16/9 ; */
  height: 100%;
}

.about-graphics {
  max-width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.about-graphics img {
  max-width: 60%;
  margin: 2rem;

  box-shadow: 0 4px 8px 0 rgba(252, 247, 247, 0.24),
    0 6px 20px 0 rgba(252, 247, 247, 0.2);
}

@media screen and (max-width: 600px) {
  .about-text {
    max-width: 100%;
    margin-inline: 50px;
  }

  .about-graphics {
    max-width: 100%;
  }
}
